
import { AxiosResponse } from 'axios';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { State, Action } from 'vuex-class';

import { Media, Playlist } from '@/types/index.d';

import DetailPanel from '@/components/DetailPanel.vue';
import SectionHeading from '@/components/heading/SectionHeading.vue';

import { deleteMedia } from '@/services/axios';
import { getMediaType } from '@/services/media';
import { getFormattedLongThaiTime } from '@/utils/time';

@Component({
  components: {
    DetailPanel,
    SectionHeading,
  },
})
export default class MediaInfo extends Vue {
  @Prop({ default: null }) readonly selectedMedia!: Media | null;

  @Action loadMedia!: () => Promise<AxiosResponse<any>>;
  @State media!: Media[];
  @State playlists!: Playlist[];

  @Watch('selectedMedia')
  onSelectedMediaChanged() {
    this.forceRerenderKey++;
  }

  get playlistsUsingSelectedMedia(): Playlist[] {
    if (!this.selectedMedia || !this.playlists.length) return [];
    const currentMediaId = this.selectedMedia.m_id;
    return this.playlists.filter(pl => pl.media.some(m => m.m_id === currentMediaId));
  }

  forceRerenderKey: number = 0;

  deleteSelectedMedia(): void {
    if (!this.selectedMedia) return;

    if (window.confirm('คุณต้องการลบไฟล์นี้หรือไม่?')) {
      deleteMedia(this.selectedMedia.op_id, this.selectedMedia.m_id)
        .then(this.loadMedia)
        .then(() => {
          this.$router.push({
            name: 'media-view',
          });
        });
    }
  }

  getFormattedLongThaiTime(date: string): string {
    let dateString: string = date;
    if (date[date.length - 1].toUpperCase() !== 'Z') dateString += 'Z';
    return getFormattedLongThaiTime(new Date(dateString));
  }

  getMediaType(typ_id: number): string {
    return getMediaType(typ_id);
  }
}
