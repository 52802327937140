
import { Component, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';

import { ALLOWED_MEDIA_UPLOAD_MIMES } from '@/config';

import { postMedia } from '@/services/axios';
import isValidHttpUrl from '@/utils/url';

import Modal from '@/components/Modal.vue';
import SectionH1 from '@/components/heading/SectionH1.vue';
import TextField from '@/components/form/TextField.vue';
import RoundButton from '@/components/form/RoundButton.vue';

@Component({
  components: {
    Modal,
    SectionH1,
    TextField,
    RoundButton,
  },
})
export default class MediaUploadModal extends Vue {
  @State opId!: number;

  isUploading: boolean = false;
  selectedFileName: string = 'โปรดเลือกไฟล์';
  uploadedPercentage: number = 0;

  /**
   * A current selected tab id.
   */
  selectedTab: string = 'upload-file-tab';
  /**
   * A web url from text field.
   */
  webUrl: string = '';
  /**
   * Get/set whether there is an error for web url text field.
   */
  isWebUrlError: boolean = false;
  /**
   * Media type tabs.
   */
  tabs: MediaTabItem[] = [
    {
      id: 'upload-file-tab',
      name: 'อัปโหลดไฟล์',
    },
    {
      id: 'add-web-tab',
      name: 'เว็บไซต์',
    },
  ];

  get ALLOWED_MEDIA_UPLOAD_MIMES(): string {
    return ALLOWED_MEDIA_UPLOAD_MIMES;
  }

  closeUploadModal(): void {
    this.$emit('close-modal');
  }

  fileChosen(): void {
    // `as any` prevents TS warning
    if (this.$refs.fileInput && (this.$refs.fileInput as any).files[0]) {
      this.selectedFileName = (this.$refs.fileInput as any).files[0].name;
    }
  }

  uploadFile(): void {
    if (!this.$refs.fileInput || !(this.$refs.fileInput as any).files[0]) return;

    this.isUploading = true;

    const file = (this.$refs.fileInput as any).files[0];

    if (file.type.includes('video')) {
      let video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);

        const formData = new FormData();

        formData.append('file', file);

        let length = video.duration - 1;
        if (length < 0) {
          length = 0;
        }
        formData.append('length', length.toFixed());

        postMedia(this.opId, formData)
          .then(this.closeUploadModal)
          .catch(err => {})
          .finally(() => {
            this.isUploading = false;
          });
      };

      video.src = URL.createObjectURL(file);
    } else {
      const formData = new FormData();
      formData.append('file', file);

      postMedia(this.opId, formData)
        .then(this.closeUploadModal)
        .catch(err => {})
        .finally(() => {
          this.isUploading = false;
        });
    }
  }

  /**
   * A callback from web url text field which invokes when its text is changed.
   * @param {string} text a text changed from web url text field.
   */
  onWebUrlTextChanged(text: string): void {
    this.webUrl = text;
    this.isWebUrlError = false;
  }

  /**
   * Method to create and save a media with type web (4) to the server. It will
   * close the modal after API is finished.
   */
  createWebMedia(): void {
    const webUrl: string = this.webUrl;
    const isWebUrlValid: boolean = isValidHttpUrl(webUrl);
    this.isWebUrlError = !isWebUrlValid;
    if (isWebUrlValid) {
      const formData = new FormData();
      formData.append('content_url', webUrl);
      formData.append('name', webUrl);
      postMedia(this.opId, formData)
        .then(this.closeUploadModal)
        .catch(err => {});
    }
  }

  /**
   * A method to set a selected tab.
   * @param {string} tabId an id of the selected media tab.
   */
  selectTab(tabId: string): void {
    this.selectedTab = tabId;
  }

  mounted() {
    setInterval(() => {
      this.uploadedPercentage++;
      this.uploadedPercentage %= 101;
    }, Math.round(Math.random() * 1000));
  }
}

/**
 * MediaTabItem is an object for tabs in media creation modal in media page.
 */
export interface MediaTabItem {
  id: string;
  name: string;
}
