import { render, staticRenderFns } from "./MediaUploadModal.vue?vue&type=template&id=3fa79bac&scoped=true"
import script from "./MediaUploadModal.vue?vue&type=script&lang=ts"
export * from "./MediaUploadModal.vue?vue&type=script&lang=ts"
import style0 from "./MediaUploadModal.vue?vue&type=style&index=0&id=3fa79bac&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fa79bac",
  null
  
)

export default component.exports