/**
 * Check if the given url string is a valid HTTP/HTTPS url or not.
 * @param string a string to check if it's a valid HTTP/HTTPS url.
 * @returns true if a given string is a valid HTTP/HTTPS url, false otherwise.
 */
export default function isValidHttpUrl(string: string): boolean {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === 'https:' || url.protocol === 'http:';
}
