
import { AxiosResponse } from 'axios';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { State, Action } from 'vuex-class';

import { Media } from '@/types/index.d';

import MediaInfo from '@/components/media/MediaInfo.vue';
import MediaUploadModal from '@/components/media/MediaUploadModal.vue';
import SectionHeading from '@/components/heading/SectionHeading.vue';
import SectionH1 from '@/components/heading/SectionH1.vue';
import SidePanel from '@/components/nav/SidePanel.vue';
import SidePanelItemBlock from '@/components/nav/SidePanelItemBlock.vue';
import Thumbnail from '@/components/Thumbnail.vue';

import { deleteMedia } from '@/services/axios';
import { getMediaType } from '@/services/media';
import { getFormattedLongThaiTime } from '@/utils/time';

@Component({
  components: {
    MediaInfo,
    MediaUploadModal,
    SectionH1,
    SidePanel,
    SidePanelItemBlock,
    SectionHeading,
    Thumbnail,
  },
})
export default class MediaView extends Vue {
  @Action loadMedia!: () => Promise<AxiosResponse<any>>;
  @State media!: Media[];

  @Watch('media', { deep: true })
  onMediaChanged() {
    this.selectMediaFromParams();
  }

  isShowingUploadModal: boolean = false;
  selectedMedia: Media | null = null;

  mounted() {
    this.selectMediaFromParams();
  }

  get filteredMedia(): Media[] {
    return this.media.filter(m => m.is_act);
  }

  closeUploadModal(): void {
    this.isShowingUploadModal = false;
    this.loadMedia();
  }

  getFormattedLongThaiTime(date: string): string {
    let dateString: string = date;
    if (date[date.length - 1].toUpperCase() !== 'Z') dateString += 'Z';
    return getFormattedLongThaiTime(new Date(dateString));
  }

  getMediaType(typ_id: number): string {
    return getMediaType(typ_id);
  }

  openUploadModal(): void {
    this.isShowingUploadModal = true;
  }

  selectMedia(medium: Media) {
    this.selectedMedia = medium;
    this.$router.push({
      name: 'media-info-view',
      params: {
        mediaId: `${medium.m_id}`,
      },
    });
  }

  selectMediaFromParams() {
    if (this.$route && this.$route.params) {
      const opId = parseInt(this.$route.params.opId, 10);
      const mediaId = parseInt(this.$route.params.mediaId, 10);

      if (opId && mediaId) {
        this.selectedMedia = this.media.find(m => m.op_id === opId && m.m_id === mediaId) || null;
      }
    }
  }
}
